<template>
  <!-- 电子发票 -->
  <div class="fapiao">
    <van-cell
      title="切换就诊人"
      icon="manager-o"
      is-link
      style="text-align: left"
      @click="openChooise()"
    >
      <template #default>
        <span style="color: #999">{{ chooiseItem.name }}</span>
      </template>
    </van-cell>
    <van-popup v-model="showPopup" position="bottom" round closeable>
      <div class="yyqr-chooise-jzk-over">
        <div class="yyqr-chooise-title">选择就诊人</div>
      </div>
      <div
        class="yyqr-chooise-jzk"
        v-for="(item, index) in list"
        :key="index"
        @click="chooiseJZPerson(item)"
      >
        <span v-if="item.id == personId" class="checked">
          <img src="@/assets/img/checked.png" alt="" />
          {{ item.name + "(" + item.idNumber + ")" }}</span
        >
        <span v-else>{{ item.name + "(" + item.idNumber + ")" }}</span>
      </div>
      <div class="yyqr-chooise-nodata" v-show="list.length == 0">
        <img
          src="../../assets/img/appoint/zanwujiuzhenren.png"
          width="100%"
          height="100%"
          alt=""
        />
      </div>
      <div class="yyqr-chooise-add" @click="addPatient">
        <van-icon name="plus" />添加就诊人
      </div>
    </van-popup>
    <van-tabs
      v-model="activeName"
      title-active-color="#00D48B "
      color="#00D48B"
      swipeable
      @change="handleTabChange"
    >
      <van-tab title="门诊发票" name="a">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="fapiao1" v-for="(tab, index1) in newsList" :key="index1">
            <div v-for="(group, gindex) in tab.data" :key="gindex">
              <div class="fapiao2">{{ group.month }}</div>
              <div
                class="fapiao3"
                v-for="(item, index) in group.list"
                :key="index"
              >
                <div class="fapiao4">
                  <div class="fapiao41">
                    <span style="padding-right: 5px"
                      >{{ item.deptName
                      }}<span style="color: #fe994e"
                        >￥{{ item.totalCosts }}</span
                      ></span
                    >
                    <div style="font-size: 14px; color: #888888">
                      {{ item.chrgDate }}
                    </div>
                  </div>
                  <div class="fapiao42">
                    <van-button
                      style="width: 100px; height: 30px"
                      color="#00D58B"
                      plain
                      hairline
                      type="primary"
                      @click="Invoice(item)"
                      >查看发票</van-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="baogao3" v-if="newsList[0].data.length == 0">
            <img src="@/assets/img/ucenter/21.png" alt="" />
          </div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="住院发票" name="b">
        <van-pull-refresh v-model="isLoading1" @refresh="onRefresh1">
          <div class="fapiao1" v-for="(tab, index1) in newsList2" :key="index1">
            <div v-for="(group, gindex) in tab.data" :key="gindex">
              <div class="fapiao2">{{ group.month }}</div>
              <div
                class="fapiao3"
                v-for="(item, index) in group.list"
                :key="index"
              >
                <div class="fapiao4">
                  <div class="fapiao41">
                    <span style="padding-right: 5px"
                      >{{ item.deptName
                      }}<span style="color: #fe994e"
                        >￥{{ item.totalCosts }}</span
                      ></span
                    >
                    <div style="font-size: 14px; color: #888888">
                      {{ item.chrgDate }}
                    </div>
                  </div>
                  <div class="fapiao42">
                    <van-button
                      style="width: 100px; height: 30px"
                      color="#00D58B"
                      plain
                      hairline
                      type="primary"
                      @click="Invoice(item)"
                      >查看发票</van-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="baogao3" v-if="newsList[0].data.length == 0">
            <img src="@/assets/img/ucenter/21.png" alt="" />
          </div>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      showPopup: false,
      isEmpty: false,
      chooiseItem: "",
      activeName: "a",
      list: [],
      invoiceType: 1, // 1门诊 2住院 #目前全部传1
      invoiceType1: 2,
      newsList: [{ data: [] }, { data: [] }],
      newsList2: [{ data: [] }, { data: [] }],
      tabList: [], //门诊发票
      tabList1: [], // 住院发票
      isLoading: false,
      isLoading1: false,
    };
  },
  methods: {
    /**
     * 判断是否有就诊人
     */
    isHasPatient() {
      if (!this.chooiseItem) {
        this.showPopup = true;
        return;
      }
    },
    Invoice(item) {
      // 查看发票页面
      window.sessionStorage.setItem("tabList", JSON.stringify(item));
      this.$router.push({
        name: "viewInvoicVe",
      });
    },
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
    // 添加就诊人
    addPatient() {
      sessionStorage.setItem("backRoute", this.$route.fullPath);
      this.$router.push("/addCardOne");
    },

    chooiseJZPerson(item) {
      sessionStorage.setItem("CurrentJZPerson", JSON.stringify(item));
      this.chooiseItem = item;
      this.personId = item.id;
      this.showPopup = false;
      this.HospitalizationInvoice();
      this.OutpatientInvoice();
      //   this.getAppoint(item.patId);
    },
    getList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        type: 1,
      };
      this.$http
        .get("/api/search/cardlist", postData)
        .then((res) => {
          if (res.status == 200) {
            this.list = res.data;
            const CurrentJZPerson = JSON.parse(
              sessionStorage.getItem("CurrentJZPerson")
            );
            if (!CurrentJZPerson) {
              if (this.list.length > 0) {
                this.personId = this.list[0].id;
                for (let i = 0; i < this.list.length; i++) {
                  if (i == 0) {
                    this.chooiseItem = this.list[i];
                  }
                  if (this.list[i].deafult) {
                    this.chooiseItem = this.list[i];
                  }
                }
                if (!this.chooiseItem.deafult) {
                  this.chooiseItem.deafult = true;
                  this.list[0].deafult = true;
                }
              }
            } else {
              this.personId = CurrentJZPerson.id;
              this.chooiseItem = CurrentJZPerson;
            }
            this.HospitalizationInvoice();
            this.OutpatientInvoice();
            this.isHasPatient();
          }
        })
        .catch((err) => {
          console.log(err);
          this.isHasPatient();
        });
    },
    dateFtt(fmt, date) {
      //author: meizz
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },

    HospitalizationInvoice() {
      // 住院发票
      let postData2 = {
        patientId: this.chooiseItem.patId,
        invoiceType: this.invoiceType,
      };
      this.$http.get("/api/record/bill", postData2).then((res) => {
        if (res.status == 200) {
          this.tabList = res.data;
          let invoiceGroupTemp = _.groupBy(res.data, function (item) {
            return moment(item.chrgDate).format("YYYY年MM月");
          });
          this.newsList = [{ data: [] }, { data: [] }];
          for (var key in invoiceGroupTemp) {
            this.newsList[0].data.push({
              month: key,
              list: invoiceGroupTemp[key],
            });
          }
        }
      });
    },
    OutpatientInvoice() {
      this.$toast.loading({
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      // 门诊发票
      let postData = {
        patientId: this.chooiseItem.patId,
        invoiceType: this.invoiceType1,
      };
      this.$http
        .get("/api/record/bill", postData)
        .then((result) => {
          this.tabList1 = result.data;
          let invoiceGroupTemp = _.groupBy(result.data, function (item) {
            return moment(item.chrgDate).format("YYYY年MM月");
          });
          this.newsList2 = [{ data: [] }, { data: [] }];
          for (var key in invoiceGroupTemp) {
            this.newsList2[0].data.push({
              month: key,
              list: invoiceGroupTemp[key],
            });
          }
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.HospitalizationInvoice();
      }, 1000);
    },
    onRefresh1() {
      setTimeout(() => {
        this.isLoading1 = false;
        this.OutpatientInvoice();
      }, 1000);
    },
    handleTabChange(name, title) {
      this.$router.replace({
        query: {
          activeName: name,
        },
      });
    },
  },
  mounted() {
    this.getList();
    this.activeName = this.$route.query.activeName;
  },
};
</script>
<style scoped>
.body {
  background-color: #f8f9f9;
}
.fapiao {
  width: 100%;
  height: 100%;
}
.fapiao1 {
  width: 9rem;

  margin: auto;
  font-size: 0.4rem;
  margin-top: 20px;
  text-align: left;
}
.fapiao2 {
  height: 50px;
  line-height: 50px;
}
.fapiao3 {
  background-color: #fff;
  padding: 5px 10px;
}
.fapiao4 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
}
.fapiao4:last-child {
  border: 0;
}
.fapiao41 {
  width: 6rem;
  height: 70px;
  line-height: 30px;
}
.yyqr-chooise-jzk-over {
  display: flex;
  width: 100%;
  align-items: center;
}
.yyqr-chooise-title {
  text-align: center;
  font-size: 0.45rem;
  width: 100%;
  padding: 6px;
  color: #373438;
}
.yyqr-chooise-jzk {
  padding: 8px;
  font-size: 0.4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #faf7fa;
}
.yyqr-chooise-jzk .checked {
  color: #03d28c;
}
.yyqr-chooise-jzk img {
  height: 0.3rem;
  width: 0.3rem;
}
.yyqr-chooise-add {
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #1ad997;
  box-sizing: border-box;
}

.yyqr-chooise-add .van-icon {
  position: relative;
  top: -2px;
  left: -2px;
}
.yyqr-chooise-nodata {
  font-size: 0.4rem;
  padding: 8px;
  color: #afafaf;
}
/deep/ .van-popup__close-icon--top-right {
  top: 6px;
}
/deep/ .van-tab--active {
  background-color: #f5f5f5;
}
</style>
